import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-forget-password-popup',
  templateUrl: './forget-password-popup.component.html',
  styleUrls: ['./forget-password-popup.component.scss']
})
export class ForgetPasswordPopupComponent {
  passwordForm: FormGroup;
  hidePassword = true;       // For password field visibility
  hideConfirmPassword = true; // For confirm password field visibility

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ForgetPasswordPopupComponent>) {
    this.passwordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required]]
    }, { validator: this.passwordMatchValidator });
  }

  togglePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
  }

  toggleConfirmPasswordVisibility() {
    this.hideConfirmPassword = !this.hideConfirmPassword;
  }

  passwordMatchValidator(form: FormGroup) {
    return form.get('password')?.value === form.get('confirmPassword')?.value 
      ? null : { 'mismatch': true };
  }

  onSubmit() {
    if (this.passwordForm.valid) {
      const passwordData = {
        password: this.passwordForm.value.password,
        confirmPassword: this.passwordForm.value.confirmPassword
      };
      this.dialogRef.close(passwordData);  // Send data back to parent
    }
  }

  passwordStrength = 0 ;

  onPasswordChange(pass:any) {
    const password = this.passwordForm.get(pass)?.value;
    this.passwordStrength = this.evaluatePasswordStrength(password);
  }

  evaluatePasswordStrength(password: string) {
    let strength = 0;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /[0-9]/.test(password);
    const hasSymbols = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const lengthValid = password.length >= 8;

    const strengthCount = [hasUpperCase, hasLowerCase, hasNumbers, hasSymbols, lengthValid].filter(Boolean).length;

    switch (strengthCount) {
  
      case 5 :
        strength = 4;
        break;
      case 4 :
        strength = 3;
        break;
      case 3:
        strength = 2;
        break;
      default:
        strength = 1;
        break;
    }

    return strength;
  }

}
