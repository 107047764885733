import { Component, model } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TermsServicePopupComponent } from '../../../popups/terms-service-popup/terms-service-popup.component';
import { ForgetPasswordPopupComponent } from '../../../popups/forget-password-popup/forget-password-popup.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls:[ './login.component.scss']
})
export class LoginComponent {
 
  loginForm: FormGroup;
  hide = true;
  checked = false;

  constructor(private dialog: MatDialog,private fb: FormBuilder) {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required, Validators.minLength(3), Validators.pattern(/^[a-zA-Z0-9]+$/)]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }


  termsAccepted: boolean = false;


  toggleHide() {
    this.hide = !this.hide;
  }

  onSubmit() {
    if (this.loginForm.valid) {
      const formValues = this.loginForm.value;
      console.log('Form Values:', formValues);
      // Handle successful login here
    } else {
      this.loginForm.markAllAsTouched();
    }
  }



  termsServicesDialog(): void {
    const dialogRef = this.dialog.open(TermsServicePopupComponent, {
      width: '1200px',
    });

    dialogRef.componentInstance.termsAccepted.subscribe((accepted: boolean) => {
      this.termsAccepted = accepted;
      console.log('Accept Terms and Conditions:', this.termsAccepted);
    });
  }

  forgetPassDialog(){
    const dialogRef = this.dialog.open(ForgetPasswordPopupComponent, {
      width: '960px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Captured Password:', result.password);
        console.log('Captured Confirm Password:', result.confirmPassword);
        
      }
    });
  }
  
}
