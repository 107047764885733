import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LdAdminComponent } from '../ld-admin/ld-admin.component';
import { LdadminDashboardComponent } from '../ldadmin-dashboard/ldadmin-dashboard.component';

const routes: Routes = [
  { path: '', component: LandingPageComponent },
  { path: 'login', component: LoginComponent },

  { path: 'ldadmin', component: LdAdminComponent },
  { path: 'ldadmin-dashboard', component: LdadminDashboardComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
