import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-terms-service-popup',
  templateUrl: './terms-service-popup.component.html',
  styleUrls:['./terms-service-popup.component.scss']
})
export class TermsServicePopupComponent {
 
  @Output() termsAccepted = new EventEmitter<boolean>();

  constructor(public dialogRef: MatDialogRef<TermsServicePopupComponent> ,private router: Router) {}

  onAccept(): void {
   
    this.termsAccepted.emit(true);
    this.dialogRef.close();
  }

  onClose(): void {
    this.dialogRef.close();
  }

}
