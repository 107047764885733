<p  class="popup-header-label">Terms of Use</p>
<div  >


    <div class="error-txt">
      <p>
        This electronic portal is provided to loanDepot Lender 
  Express partners for use in the submission of program-related 
  materials and information. Unauthorized use is prohibited. By 
  continuing to use this portal, you certify that you are acting on 
  behalf of a loanDepot Lender Express partner and you agree to abide by the 
  Lender Express program’s terms.</p>
        
    </div>
   
  <div align="end" class="popup-footer">
    
    <button mat-button class="accept-button" (click)="onClose()" style="color:#FFFFFF;"> ACCEPT AND CONTINUE</button>
    
  </div>

</div>








