export const environment = {
  production: false,
  displayLogs: true,
  userflow: 'B2C_1_lenderExpress_SSO_SignUpSignIn',


  msalConfig: {
    auth: {
      authority:
        'https://login.microsoftonline.com/19479f88-8eac-45d2-a1bf-69d33854a3fa'
    }
  },
  apiConfig: {
    scopes: ['api://los-globalpermissions-dv/list'],

  }
};
