<h2 mat-dialog-title class="popup-header-label">Create Your Password</h2>
<mat-dialog-content class="custom-input-popup">
 <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()" class="d-flex justify-content-between p-2 ">
  <div  >
  <mat-form-field appearance="outline" >
    <mat-label>Password</mat-label>
    <input 
      matInput 
      [type]="hidePassword ? 'password' : 'text'" 
      placeholder="Password" 
      formControlName="password"
      (input)="onPasswordChange('password')"
      >
    <button 
      mat-icon-button 
      matSuffix 
      (click)="togglePasswordVisibility()">
      <mat-icon class="eye-icon-white">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
    <mat-hint *ngIf="passwordForm.get('password')?.touched && passwordForm.get('password')?.hasError('required')">Password is required.</mat-hint>
    <mat-hint *ngIf="passwordForm.get('password')?.touched && passwordForm.get('password')?.hasError('minlength')">Minimum length is 8 characters.</mat-hint>
  </mat-form-field>
</div>

  <div>
  <mat-form-field appearance="outline">
    <mat-label>Confirm Password</mat-label>
    <input 
      matInput 
      [type]="hideConfirmPassword ? 'password' : 'text'" 
      placeholder="Re-enter Password" 
      formControlName="confirmPassword"
      (input)="onPasswordChange('confirmPassword')"
      >
    <button 
      mat-icon-button 
      matSuffix 
      (click)="toggleConfirmPasswordVisibility()">
      <mat-icon class="eye-icon-white">{{ hideConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
    <mat-hint *ngIf="passwordForm.get('confirmPassword')?.touched && passwordForm.get('confirmPassword')?.hasError('required')">Confirmation is required.</mat-hint>
    <mat-hint *ngIf="passwordForm.get('confirmPassword')?.touched && passwordForm.hasError('mismatch')">Passwords do not match.</mat-hint>
  </mat-form-field>
  </div>

</form>



<div class="password-strength-container">
  <p class="mr-2"><img src="assets/icons/lock-icon.svg" alt="icon"> Strength : &nbsp; &nbsp; </p>
  <div class="strength-box" [ngClass]="{'filled': passwordStrength >= 1 }"></div>
  <div class="strength-box" [ngClass]="{'filled': passwordStrength > 1 && passwordStrength >=2}"></div>
  <div class="strength-box" [ngClass]="{'filled': passwordStrength > 2 && passwordStrength >=3}"></div>
  <div class="strength-box" [ngClass]="{'filled':passwordStrength > 3 && passwordStrength >=3}"></div>

</div>


<div class="password-requirements">
  <p class="password-hint">Password Must Contain:</p >
  <ul class="item">
    <li class="item-list">One letter or number (e.g., abc123)</li>
    <li class="item-list">One symbol (e.g.,#$%)</li>
    <li class="item-list">At least 8 characters</li>
    <li class="item-list">Cannot have spaces and will be case sensitive</li>
  </ul>
</div>



  
</mat-dialog-content>
<mat-dialog-actions align="end" class="popup-footer">
  <button mat-button class="accept-button m-2" (click)="onSubmit()" [disabled]="passwordForm.invalid">DONE</button>
</mat-dialog-actions>
