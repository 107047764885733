import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { filter } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../auth.service';

interface aad {
  exp?: number;
  nbf?: number;
  ver?: string;
  iss?: string;
  sub?: string;
  aud?: string;
  nonce?: string;
  iat?: number;
  auth_time?: number;
  oid?: string;
  extension_FirstName?: string;
  extension_LastName?: string;
  emails?: string[];
  tfp?: string;
}

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent {
  token: string | null = null;
  decodedToken: aad = {};
  status: string = '';

  inputValue: string = '';
  clientId: string = localStorage.getItem('b2c-client-id') || '';
  redirectUrl: string = localStorage.getItem('redirectUrl') || '';
  baseUrlB2C: string = localStorage.getItem('baseUrlB2C') || '';
  error: boolean = false;
  loginUrl: string = `${this.baseUrlB2C}${environment.userflow}&client_id=${this.clientId}&nonce=defaultNonce&redirect_uri=${this.redirectUrl}&scope=openid&response_type=id_token&prompt=login`;

  landingForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.fragment.subscribe((fragment) => {
      const tokenParam = fragment ? this.getTokenFromFragment(fragment) : null;
      this.token = tokenParam;
      if (this.token) {
        this.decodeToken(this.token);
        localStorage.setItem('token', JSON.stringify(this.decodedToken));
        localStorage.setItem('tokenValue', this.token);
      }
    });

    const urlFragment = window.location.hash;
    const tokenMatch = urlFragment.match(/id_token=([^&]+)/);

    if (urlFragment.includes('id_token=') && tokenMatch) {
      this.router.navigate(['dashboard']);
    } else if (sessionStorage.getItem('aadToken')) {
      this.router.navigate(['ldadmin-dashboard']);
    } else {
      window.location.href = this.loginUrl;
    }

    this.landingForm = this.fb.group({
      username: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern('^[a-zA-Z0-9]+$') // Only letters and numbers
        ]
      ]
    });
  }

  // submit(): void {
  //   if (this.landingForm.valid) {
  //     const enteredValue = this.landingForm.value.username;
  //     console.log('Submitted Value:', enteredValue);

  //     if (this.inputValue === 'loandepot' || this.inputValue === 'loan depot') {
  //       alert('You entered: ' + this.inputValue);
  //     } else {
  //       window.location.href = `https://mellob2cdev.b2clogin.com/MelloB2CDev.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_lenderExpress_SSO_SignUpSignIn&client_id=${this.clientId}&nonce=defaultNonce&redirect_uri=https%3A%2F%2Flocalhost%3A4200&scope=openid&response_type=id_token&prompt=login`;
  //     }

  //   } else {
  //     this.landingForm.markAllAsTouched(); // To trigger validation messages for all fields
  //   }
  // }

  // Function to extract token from the fragment
  getTokenFromFragment(fragment: string): string | null {
    const params = new URLSearchParams(fragment);
    return params.get('id_token'); // Extract 'id_token' from fragment
  }

  decodeToken(token: string): void {
    try {
      // Decode the token using jwt-decode (browser-friendly)
      this.decodedToken = jwtDecode(token);
      if (
        this.decodedToken &&
        this.decodedToken.aud === '2414fe93-3ed4-406e-9ddf-76f9b057685d' &&
        this.decodedToken.emails &&
        this.decodedToken.emails[0].includes('@loandepot.com')
      ) {
        this.status = 'correct';
      } else {
        this.status = 'Incorrect';
      }
    } catch (error) {
      console.error('Error decoding token', error);
      this.decodedToken = {};
    }
  }
}
