<div class="login-container" >

  <div class="d-flex justify-content-center subLoginContainer">
    <div>
      <div class="mt-4">
        <img src="assets/images/loandepo-express.png" alt="Logo">
      </div>
  
      <div class="mt-4 d-flex justify-content-center">
        <div>
          <p class="text-center portal-label mb-4">Lender Express Portal</p>
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="d-flex flex-column">
            
            <!-- Username Field -->
            <mat-form-field appearance="outline" class="custom-login-input mt-4">
              <mat-label class="hint-text">Username</mat-label>
              <input formControlName="username" matInput placeholder="Username" class="input-text">
              <mat-error *ngIf="loginForm.get('username')?.invalid && loginForm.get('username')?.touched">
                <ng-container *ngIf="loginForm.get('username')?.errors?.['required']">Username is required.</ng-container>
                <ng-container *ngIf="loginForm.get('username')?.errors?.['minlength']">Username must be at least 3 length.</ng-container>
                <ng-container *ngIf="loginForm.get('username')?.errors?.['pattern']">Username can only contain letters and numbers.</ng-container>
              </mat-error>
              <!-- <mat-hint *ngIf="loginForm.get('username')?.touched" class="input-hint-text">
                Username must be at least 3 characters.
              </mat-hint> -->
            </mat-form-field>
      
            <!-- Password Field -->
            <mat-form-field appearance="outline" class="custom-login-input mt-4">
              <mat-label class="hint-text">Password</mat-label>
              <input formControlName="password" matInput [type]="hide ? 'password' : 'text'" placeholder="Password" class="input-text">
              <button mat-icon-button matSuffix (click)="toggleHide()">
                <mat-icon class="eye-icon-white">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
              </button>
              <mat-error *ngIf="loginForm.get('password')?.invalid && loginForm.get('password')?.touched">
                <ng-container *ngIf="loginForm.get('password')?.errors?.['required']">Password is required.</ng-container>
                <ng-container *ngIf="loginForm.get('password')?.errors?.['minlength']">Password must be at least 6 length.</ng-container>
              </mat-error>
              <!-- <mat-hint *ngIf="loginForm.get('password')?.touched" class="input-hint-text">
                Password must be at least 6 characters.
              </mat-hint> -->
            </mat-form-field>
      
            <!-- Remember Me Checkbox -->
            <section class="example-section mt-3 custom-checkbox">
              <mat-checkbox [(ngModel)]="checked" class="hint-text">Remember my login</mat-checkbox>
            </section>
      
            <!-- Submit Button -->
            <button mat-raised-button class="custom-login-button" type="submit" (click)="termsServicesDialog()">LOG IN</button>
            <!-- [disabled]="loginForm.invalid" -->
          </form>
        </div>
      </div>
      
      
    </div>
  </div>
  
  
  

   <p  class="text-center mt-3 terms-text-label">By logging in, you agree to loanDepot’s <span class="end-text" (click)="termsServicesDialog()" > Terms of Service</span></p>
    <p  class="text-center mt-3 terms-text-label">Have trouble logging in?<span class="end-text" (click)="forgetPassDialog()">Reset Password</span> </p>

</div>  